const HomeHeader = () => {
    return (
        <span className="mainHeader">
            <div className="mainFrame">
                <h3 className="mainTitle">Unit<b className="mainTitleBold">HQ</b></h3>
                <div className="headerInsignias">
                    <img src="./USAJROTC.png" alt="USAJROTC" className="headerInsignia" />
                    <img src="./MCJROTC.png" alt="MCJROTC" className="headerInsignia" />
                    <img src="./NJROTC.png" alt="NJROTC" className="headerInsignia" />
                    <img src="./AFJROTC.png" alt="AFJROTC" className="headerInsignia" />
                    <img src="./CGJROTC.png" alt="CGJROTC" className="headerInsignia" />
                    <img src="./SFJROTC.png" alt="SFJROTC" className="headerInsignia" />
                </div>
            </div>
        </span>
    );
}

export default HomeHeader;