import { Outlet, Link } from "react-router-dom";
import FooterFooter from "./FooterFooter";
import PageHeader from "./PageHeader";

const Terms = () => {
    return (
        <div className="App legalPage">
            <PageHeader />
            <div className="legalContent">
                <div className="mainFrame">
                    <h1>Terms of use</h1>

                    <h2>What information do we collect?</h2>
                    <p>
                        Please read this Terms of Use agreement carefully.
                        By using this website, you signify that you have read, understand and agree to be bound by all of the terms and conditions as set forth in this (1) Terms of Use Agreement, (2) Privacy Policy.
                        We may change the terms of the Agreement at any time and without notice, effective upon the posting of the revised Agreement.
                        Your continued use of this website shall be considered your acceptance of the revised Agreement.
                    </p>

                    <h2>External Links Disclaimer</h2>
                    <p>
                        The appearance of hyperlinks to external sites does not constitute endorsement by us of the linked website or the information, products or services contained therein.
                        We not exercise any editorial control over the information you may find at these locations.
                        Such links are provided consistent with the stated purpose of this website.
                    </p>
                </div>
            </div>
            <TermsFooter />
        </div>
    );
}

function TermsFooter() {
    return (
        <div className="mainFooter">
            <div className="mainFrame">
                <ul className="mainFooterItems">
                    <li className="homeLink"><Link to="/">Unit<b className="mainTitleBold">HQ</b></Link></li>
                    <li><Link to="/privacy">Privacy</Link></li>
                </ul>
                <FooterFooter/>
            </div>
        </div>
    );
}

export default Terms;