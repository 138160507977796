import React from 'react';
import { Outlet, Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import FooterFooter from './FooterFooter';
import HomeHeader from './HomeHeader';

function App() {
  return (
      <div className="App homePage">
          <HomeHeader />
          {/* <header className="App-header"> */}
          <div className="homeContent">
              <div className="mainFrame">
                  <div className="mainHomeHeadSpace">
                  </div>
                  <div className="mainHomeSlogan">
                      <h1>You and Your Unit</h1>
                      <h2>Events, guide, info, reference, awards</h2>
                  </div>
                  <div className="mainLogo">
                      <center>
                          <img src={logo} className="App-logo" alt="logo" />
                      </center>
                  </div>
                  <div className="mainHomeBottomSpace">
                  </div>
              </div>
          </div>
          {/* </header> */}
          <Footer />
      </div>
  );
}

function Footer() {
    return (
        <div className="mainFooter">
            <div className="mainFrame">
                <ul className="mainFooterItems">
                    <li><Link to="/privacy">Privacy</Link></li>
                    <li><Link to="/terms">Terms</Link></li>
                </ul>
                <FooterFooter/>
            </div>
        </div>
    );
}

export default App; 
