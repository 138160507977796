import { Outlet, Link } from "react-router-dom";
import FooterFooter from "./FooterFooter";
import PageHeader from "./PageHeader";

const Privacy = () => {
    return (
        <div className="App legalPage">
            <PageHeader />
            <div className="legalContent">
                <div className="mainFrame">
                <h1>Privacy Policy</h1>

                <h2>What information do we collect?</h2>
                <p></p>

                <h2>What do we use your information for?</h2>
                <p></p>

                <h2>Do we use cookies?</h2>
                <p>We do not use cookies.</p>

                <h2>Do we disclose any information to outside parties?</h2>
                <p>
                    We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
                    This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                    We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.
                    However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                </p>

                <h2>California Online Privacy Protection Act Compliance</h2>
                <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.</p>

                <h2>Childrens Online Privacy Protection Act Compliance</h2>
                <p>We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age.
                    Our website, apps, products and services are all directed to people who are at least 13 years old or older.</p>

                <h2>Changes to our Privacy Policy</h2>
                <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.</p>
                    <p>This policy was last modified on Oct-25-2023</p>
                </div>
            </div>
            <PrivFooter />
        </div>
    );
}

function PrivFooter() {
    return (
        <div className="mainFooter">
            <div className="mainFrame">
                <ul className="mainFooterItems">
                    <li className="homeLink"><Link to="/">Unit<b className="mainTitleBold">HQ</b></Link></li>
                    <li><Link to="/terms">Terms</Link></li>
                </ul>
                <FooterFooter />
            </div>
        </div>
    );
}

export default Privacy;